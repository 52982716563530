'use client';

import Link from '@components/Link';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@ngg/icons';
import type { LayoutFooterNavigationFragment } from '@server/gql/graphql';
import { useState } from 'react';

import { cn } from '@/lib/utils';

export default function FooterNavigation({
    data,
    locale,
}: {
    data: LayoutFooterNavigationFragment;
    locale: string | undefined;
}) {
    const [open, setOpen] = useState(false);

    return (
        <Disclosure as="div">
            <h3 className="text-sm">
                <Disclosure.Button
                    onClick={() => setOpen(!open)}
                    className="flex w-full items-center justify-between px-3 pb-4 pt-4 font-medium tracking-wide sm:hidden">
                    {data?.title}
                    <span className="text-base sm:hidden">
                        {open && <MinusIcon />}
                        {!open && <PlusIcon />}
                    </span>
                </Disclosure.Button>

                <span className="hidden px-3 pt-4 font-medium tracking-wide sm:block sm:pb-3">
                    {data?.title}
                </span>
            </h3>

            <Disclosure.Panel
                as="ul"
                static
                className={cn(
                    'max-w-60 px-3 pb-4 text-sm space-y-1',
                    open ? 'block' : 'hidden sm:block',
                )}>
                {data?.sublinksCollection?.items?.map((item) => (
                    <li key={item?.sys.id}>
                        <Link
                            locale={locale}
                            href={item?.internalLink?.slug}
                            className=" hover:underline">
                            {item?.title}
                        </Link>
                    </li>
                ))}
            </Disclosure.Panel>
        </Disclosure>
    );
}
