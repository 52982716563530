import { Button, Dialog } from '@components/ui';
import { useHeaderContext } from '@context/headerContext';
import { MobileMenuContext } from '@context/mobileMenuContext';
import { ArrowLeftIcon } from '@ngg/icons';
import { useContext } from 'react';

export default function MobileMenuHeader() {
    const { dictionary } = useHeaderContext();
    const { navigationTree, setNavigationTree, setNavigationDirection } =
        useContext(MobileMenuContext);

    const isSubMenuActive = navigationTree.length > 1;

    if (isSubMenuActive) {
        return (
            <Dialog.Title>
                <Button
                    className="flex items-center"
                    onClick={() => {
                        setNavigationDirection('left');
                        setNavigationTree((prev) => {
                            if (prev.length === 1) return prev;

                            return prev.slice(0, prev.length - 1);
                        });
                    }}>
                    <ArrowLeftIcon className="mr-1 text-lg" />
                    {dictionary?.back}
                </Button>
            </Dialog.Title>
        );
    }

    return <Dialog.Title>{dictionary?.menu}</Dialog.Title>;
}
