import { envConfig } from '@/config/env';

export function getSearchPathname(locale = envConfig.NEXT_PUBLIC_APP_LOCALE) {
    switch (locale?.toLowerCase()) {
        case 'sv-se':
            return 'sok';
        case 'dk':
            return 'sogning';
        case 'nb-no':
            return 'sog';
        case 'fi':
            return 'haku';
        case 'nl':
            return 'zoeken';
        case 'en-gb':
        default:
            return 'search';
    }
}
