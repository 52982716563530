'use client';

import type { HTMLProps } from 'react';
import { useEffect } from 'react';

import { cn } from '@/lib/utils';
import { useSearchStore } from '@/stores/searchStore';

import { useSearch } from './Context';

const SearchList = ({
    children,
    className,
    ...props
}: HTMLProps<HTMLUListElement>) => {
    const { selected, open, setOpen } = useSearch();
    const searchQuery = useSearchStore((state) => state.searchQuery);
    const setSearchFocus = useSearchStore((state) => state.setSearchFocus);

    useEffect(() => {
        return () => {
            setOpen(false);
            setSearchFocus(false);
        };
    }, [setSearchFocus, setOpen]);

    if (!open || !searchQuery) return null;

    return (
        <ul
            data-active={selected}
            className={cn(
                'absolute left-0 top-full z-50 flex min-w-full -translate-y-[2px] flex-col bg-inherit',
                className,
            )}
            {...props}>
            {children}
        </ul>
    );
};

SearchList.displayName = 'SearchList';

export default SearchList;
