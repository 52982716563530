'use client';

import { MarketSelectorDropdown } from '@components/MarketSelector';
import { useHeaderContext } from '@context/headerContext';

import ServicePopup from './ServicePopup';
import StorePopup from './StorePopup';

const RightLinks = () => {
    const { stores, services, locale } = useHeaderContext();

    return (
        <div className="hidden flex-1 items-center justify-end lg:flex">
            {services && Boolean(services.length) && (
                <ServicePopup locale={locale} services={services} />
            )}
            {stores && Boolean(stores.length) && <StorePopup stores={stores} />}
            <MarketSelectorDropdown />
        </div>
    );
};

RightLinks.displayName = 'RightLinks';

export default RightLinks;
