'use client';

import type { ContentfulAssetFragment, Maybe } from '@server/gql/graphql';
import type { ImageProps as NextImageProps } from 'next/image';
import Image from 'next/image';
import type { ComponentPropsWithoutRef } from 'react';

import { breakpoints } from '@/config/breakpoints';
import { cn } from '@/lib/utils';
import { getContentfulImageProps } from '@/utils/getContentfulImageProps';

type PictureProps = ComponentPropsWithoutRef<'picture'>;

type ImageProps = Omit<NextImageProps, 'src' | 'alt'>;

type ContentfulPictureProps = {
    pictureProps?: PictureProps;
    imageProps?: ImageProps;
    desktopImage?: Maybe<ContentfulAssetFragment>;
    mobileImage?: Maybe<ContentfulAssetFragment>;
    mobileBreakpoint?: (typeof breakpoints)[keyof typeof breakpoints];
};

const ContentfulPicture = ({
    pictureProps,
    imageProps,
    desktopImage,
    mobileImage,
    mobileBreakpoint = breakpoints.lg,
}: ContentfulPictureProps) => {
    const defaultImageUrl = desktopImage?.url ?? mobileImage?.url;

    if (!defaultImageUrl) {
        return null;
    }

    const defaultImageWidth =
        desktopImage?.width ?? mobileImage?.width ?? undefined;
    const defaultImageHeight =
        desktopImage?.height ?? mobileImage?.height ?? undefined;
    const defaultImageAlt = desktopImage?.title ?? mobileImage?.title ?? '';

    const mobileImageProps = getContentfulImageProps(mobileImage);

    return (
        <picture {...pictureProps}>
            {mobileImageProps?.srcSet && (
                <source
                    srcSet={mobileImageProps.srcSet}
                    media={`(max-width: ${mobileBreakpoint}px)`}
                />
            )}
            <Image
                {...imageProps}
                className={cn('object-cover', imageProps?.className)}
                src={defaultImageUrl}
                width={defaultImageWidth}
                height={defaultImageHeight}
                alt={defaultImageAlt}
            />
        </picture>
    );
};

ContentfulPicture.displayName = 'ContentfulPicture';

export default ContentfulPicture;
