import {
    DANISH_TRUSTPILOT_LOCALES,
    FINNISH_TRUSTPILOT_LOCALES,
    NORWEGIAN_TRUSTPILOT_LOCALES,
    SWEDISH_TRUSTPILOT_LOCALES,
} from '@/config/trustpilot';

export default function TrustpilotWidget({ locale }: { locale: string }) {
    if (SWEDISH_TRUSTPILOT_LOCALES.includes(locale)) {
        return (
            <div
                className="trustpilot-widget"
                data-locale="sv-SE"
                data-template-id="54ad5defc6454f065c28af8b"
                data-businessunit-id="5c92c1a87a437100018d1da0"
                data-style-height="240px"
                data-style-width="100%"
                data-theme="light"
                data-tags="ngse"
                data-stars="4,5"
                data-review-languages="sv"
                data-text-color="#000000">
                <a
                    href="https://se.trustpilot.com/review/nordiskagalleriet.se"
                    target="_blank"
                    rel="noopener">
                    Trustpilot
                </a>
            </div>
        );
    }
    if (NORWEGIAN_TRUSTPILOT_LOCALES.includes(locale)) {
        return (
            <div
                className="trustpilot-widget"
                data-locale="nb-NO"
                data-template-id="54ad5defc6454f065c28af8b"
                data-businessunit-id="6016e8ce12060c0001e9b716"
                data-style-height="240px"
                data-style-width="100%"
                data-theme="light"
                data-tags="ngno"
                data-stars="4,5"
                data-review-languages="no,nb,nn"
                data-text-color="#000000">
                <a
                    href="https://no.trustpilot.com/review/nordiskagalleriet.no"
                    target="_blank"
                    rel="noopener">
                    Trustpilot
                </a>
            </div>
        );
    }
    if (DANISH_TRUSTPILOT_LOCALES.includes(locale)) {
        return (
            <div
                className="trustpilot-widget"
                data-locale="da-DK"
                data-template-id="54ad5defc6454f065c28af8b"
                data-businessunit-id="6016e8d744d371000130797d"
                data-style-height="240px"
                data-style-width="100%"
                data-theme="light"
                data-tags="ngdk"
                data-stars="4,5"
                data-review-languages="da"
                data-text-color="#000000">
                <a
                    href="https://dk.trustpilot.com/review/nordiskagalleriet.dk"
                    target="_blank"
                    rel="noopener">
                    Trustpilot
                </a>
            </div>
        );
    }
    if (FINNISH_TRUSTPILOT_LOCALES.includes(locale)) {
        return (
            <div
                className="trustpilot-widget"
                data-locale="fi-FI"
                data-template-id="54ad5defc6454f065c28af8b"
                data-businessunit-id="6016e8d32bc7cf00018d63bc"
                data-style-height="240px"
                data-style-width="100%"
                data-theme="light"
                data-tags="ngfi"
                data-stars="4,5"
                data-review-languages="fi"
                data-text-color="#000000">
                <a
                    href="https://fi.trustpilot.com/review/nordiskagalleriet.fi"
                    target="_blank"
                    rel="noopener">
                    Trustpilot
                </a>
            </div>
        );
    }
    return null;
}
