'use client';

import { useEffect, useState } from 'react';

const getDateValues = (date: number): [number[], boolean] => {
    const days = Math.floor(date / (1000 * 60 * 60 * 24));
    const hours = Math.floor((date % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((date % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((date % (1000 * 60)) / 1000);

    const isExpired = date <= 0;

    return [[days, hours, minutes, seconds], isExpired];
};

export default function useCountdownToDate(endDate: Date): [number[], boolean] {
    const countdownDate = new Date(endDate).getTime();
    const [countdown, setCountdown] = useState(
        countdownDate - new Date().getTime(),
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(countdownDate - new Date().getTime());
        }, 1000);
        if (countdown < 0) clearInterval(interval);

        return () => clearInterval(interval);
    }, [countdownDate, countdown]);

    return getDateValues(countdown);
}
