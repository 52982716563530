import Link from '@components/Link';
import { useHeaderContext } from '@context/headerContext';
import type { HeaderStoreFragment } from '@server/gql/graphql';
import { useMemo } from 'react';

import getOpeningHours from './getOpeningHours';
import OpeningHour from './OpeningHour';

export default function StoreCard({ store }: { store: HeaderStoreFragment }) {
    const { dictionary } = useHeaderContext();
    const [openingHours, irregularHours] = useMemo(
        () => getOpeningHours(store),
        [store],
    );
    const { locale } = useHeaderContext();
    return (
        <div className="px-4 py-6 text-sm space-y-4">
            <div className="flex flex-col">
                <h3 className="font-medium uppercase">{store?.title}</h3>
                {store?.address && <span>{store.address}</span>}
                {store?.postalCode && store.city && (
                    <span>
                        {store.postalCode} {store.city}
                    </span>
                )}
                {store.storePage?.slug && (
                    <Link
                        className="underline"
                        href={store.storePage.slug}
                        locale={locale}>
                        {dictionary?.findUs}
                    </Link>
                )}
                {store?.email && (
                    <a
                        className="mt-4 hover:underline"
                        href={`mailto:${store.email}`}>
                        {store.email}
                    </a>
                )}
                {store?.phoneNumber && (
                    <a
                        className="hover:underline"
                        href={`tel:${store.phoneNumber.replace(/[-\s]/g, '')}`}>
                        {store.phoneNumber}
                    </a>
                )}
            </div>
            {openingHours?.length > 0 ? (
                <div>
                    <h3 className="font-medium uppercase">
                        {dictionary?.openingHours}
                    </h3>
                    {openingHours.map((item) => (
                        <OpeningHour
                            key={item.days.at(0)}
                            item={item}
                            closedTitle={dictionary?.closed}
                        />
                    ))}
                </div>
            ) : null}
            {irregularHours?.length > 0 ? (
                <div>
                    <h3 className="font-medium uppercase">
                        {dictionary?.irregularOpeningHours}
                    </h3>
                    {irregularHours.map((item) => (
                        <OpeningHour
                            key={item.days.at(0)}
                            item={item}
                            closedTitle={dictionary?.closed}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
}
