import type { Loop54AutocompleteResponse } from 'types/loop54';

export function findAutocompleteMatch({
    data,
    query,
}: {
    data?: Loop54AutocompleteResponse | null;
    query: string;
}) {
    const matches = [
        ...(data?.brands ?? []),
        ...(data?.designers ?? []),
        ...(data?.categories ?? []),
    ];

    const match = matches.find(
        (item) => item.contentName.toLowerCase() === query.toLowerCase(),
    );

    return match;
}
