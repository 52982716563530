import debounce from 'lodash.debounce';
import { useLayoutEffect, useState } from 'react';

import { type Breakpoint, breakpoints } from '@/config/breakpoints';

const checkBreakpoint = (
    windowSize: {
        width: number;
    },
    width?: Breakpoint,
    maxWidth?: Breakpoint,
) => {
    if (width && maxWidth) {
        return (
            windowSize.width >= breakpoints[width] &&
            windowSize.width < breakpoints[width]
        );
    }
    if (width) {
        return windowSize.width >= breakpoints[width] || false;
    }
    if (maxWidth) {
        return windowSize.width < breakpoints[maxWidth] || false;
    }

    return false;
};

export default function useWindowSize(
    width?: Breakpoint,
    maxWidth?: Breakpoint,
    debounceWait = 250,
) {
    const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

    useLayoutEffect(() => {
        if (typeof window === 'undefined') return undefined;

        const handleResize = debounce(() => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }, debounceWait);
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [debounceWait]);

    return {
        width: windowSize.width,
        height: windowSize.height,
        isBreakpoint: checkBreakpoint(windowSize, width, maxWidth),
    };
}
