'use client';

import { useHeaderContext } from '@context/headerContext';
import { useTopbarContext } from '@context/topbarContext';
import type { HTMLAttributes } from 'react';
import { useMemo } from 'react';

import USPItem from './UspItem';

type HeaderUspsProps = HTMLAttributes<HTMLDivElement>;

const UspList = ({ ...props }: HeaderUspsProps) => {
    const { locale } = useHeaderContext();
    const { uspCollection } = useTopbarContext();
    const items = useMemo(
        () =>
            uspCollection?.items?.filter(
                (item) => item?.__typename === 'NavigationLink',
            ),
        [uspCollection?.items],
    );

    if (!items?.length) return null;

    return (
        <div
            className="flex items-center justify-center divide-x overflow-x-auto overflow-y-hidden max-xl:hidden"
            {...props}>
            {items.map((item, index) => {
                if (!item) return null;

                return (
                    <div
                        className="border-x-transparent lg:border-x-color-base"
                        key={item.sys.id + index}>
                        <USPItem
                            hideArrow={items.length > 1}
                            item={item}
                            locale={locale}
                        />
                    </div>
                );
            })}
        </div>
    );
};

UspList.displayName = 'UspList';

export default UspList;
