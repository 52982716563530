import type { NavigationLink } from '@components/header/types';
import { create } from 'zustand';

const CLEAR_DELAY_IN_MILLISECONDS = 200;

type MenuStore = {
    activeLink?: NavigationLink;
    setActiveLink: (link?: NavigationLink) => void;
    desktopMenuOpen: boolean;
    setDesktopMenuOpen: (open: boolean) => void;
    mobileMenuOpen: boolean;
    setMobileMenuOpen: (open: boolean) => void;
    clearActiveWithDelay: () => void;
};

export const useMenuStore = create<MenuStore>((set) => ({
    activeLink: undefined,
    setActiveLink: (link?: NavigationLink) => set({ activeLink: link }),
    desktopMenuOpen: false,
    setDesktopMenuOpen: (open: boolean) => set({ desktopMenuOpen: open }),
    mobileMenuOpen: false,
    setMobileMenuOpen: (open: boolean) => set({ mobileMenuOpen: open }),
    clearActiveWithDelay: () => {
        setTimeout(() => {
            set({ activeLink: undefined });
        }, CLEAR_DELAY_IN_MILLISECONDS);
    },
}));
