import type { Navigation, NavigationLink, Page } from '@server/gql/graphql';

const getChildrenSlugs = (navLink: Navigation | NavigationLink): string[] =>
    navLink.sublinksCollection?.items
        .map((item) => item?.internalLink?.slug)
        .filter(Boolean) as string[];

export type FlattenedNavigation = {
    [key: string]: Partial<Page> & { children?: string[] };
};

export default function flattenNavigation(
    tree: Navigation,
): FlattenedNavigation {
    const map: FlattenedNavigation = {};

    if (!tree) {
        return map;
    }

    if (!tree?.sublinksCollection?.items?.length) {
        return map;
    }

    /**
     * This function will call itself recursively.
     * @param item
     */
    function flattenBranch(item: NavigationLink) {
        if (
            item &&
            item.sublinksCollection?.items?.length &&
            item.internalLink?.slug
        ) {
            map[item.internalLink?.slug] = {
                ...(item.internalLink as Page),
                children: getChildrenSlugs(item),
            };

            for (
                let i = 0;
                i < item?.sublinksCollection?.items.length;
                i += 1
            ) {
                flattenBranch(
                    item?.sublinksCollection?.items[i] as NavigationLink,
                );
            }
        } else if (item && item.internalLink?.slug) {
            map[item.internalLink?.slug] = {
                ...(item.internalLink as Page),
            };
        }
    }

    for (let i = 0; i < tree?.sublinksCollection?.items?.length; i += 1) {
        map.root = {
            children: getChildrenSlugs(tree),
        };

        flattenBranch(tree?.sublinksCollection?.items[i] as NavigationLink);
    }

    return map;
}
