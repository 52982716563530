'use client';

import RichText from '@blocks/RichText';
import Link from '@components/Link';
import Image from 'next/image';
import type { CSSProperties } from 'react';
import { useEffect, useState } from 'react';

import useCountdownToDate from '@/utils/hooks/useCountdownToDate';

type Props = {
    cta: any;
    image: any;
    endDate: string;
    heading: string;
    paragraph: string;
    backgroundColor: string;
    textColor: string;
    market: string;
    locale: string | undefined;
};

export default function Countdown(props: Props) {
    const {
        cta,
        image,
        endDate,
        heading,
        paragraph,
        backgroundColor = '#000000',
        textColor: color = '#ffffff',
        market,
        locale,
    } = props;
    const [timeItems, isExpired] = useCountdownToDate(new Date(endDate));
    const labels = ['D', 'H', 'M', 'S'];
    const [canRender, setCanRender] = useState(false);
    const style = {
        backgroundColor,
        color,
    } as CSSProperties;

    useEffect(() => {
        if (endDate) {
            setCanRender(true);
        }
    }, [endDate]);

    if (isExpired || !endDate) return null;
    return (
        <div style={style} className="relative h-[50vh] w-full overflow-hidden">
            <div className="h-full w-full">
                {image && (
                    <Image
                        className="absolute inset-0 h-auto w-full"
                        src={image?.image?.url ?? ''}
                        alt={image.imageText ?? '' ?? 'alt text missing'}
                        width={image?.image?.width ?? 500}
                        height={image?.image?.width ?? 500}
                    />
                )}
                <div className="absolute inset-0 h-full w-full">
                    <div className="flex h-full w-full flex-col justify-between">
                        <h2 className="sm:text-6xl m-5 text-center text-4xl font-bold uppercase">
                            {heading}
                        </h2>
                        {canRender && (
                            <div className="flex justify-center ">
                                {timeItems.map((item, i) => (
                                    <div key={item * Math.random()}>
                                        <div className="place-items-center px-2">
                                            {String(item).padStart(2, '0')}
                                            {labels[i]}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="m-5 flex flex-col items-center justify-center">
                            <div className="max-w-[75ch] px-2 text-center">
                                <RichText
                                    content={paragraph}
                                    locale={locale}
                                    market={market}
                                />
                            </div>
                            {cta?.href && cta?.title && (
                                <div className="mt-5 flex justify-center sm:mt-10">
                                    <Link
                                        locale={locale}
                                        className="w-[192px] bg-black py-4 text-center text-sm font-semibold uppercase text-white"
                                        href={cta?.href?.slug}>
                                        {cta.title}
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
