'use client';

import type { NavigationLink } from '@components/header/types';
import type { HeaderFragment, HeaderStoreFragment } from '@server/gql/graphql';
import { useScroll } from '@server/hooks';
import type { ScrollDirection } from '@server/hooks/useScroll';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { Dictionary } from 'types';

import { envConfig } from '@/config/env';

type HeaderContextProps = {
    locale?: string;
    leftLinksCollection?: HeaderFragment['leftLinksCollection'];
    stores?: HeaderStoreFragment[];
    services?: HeaderStoreFragment[];
    dictionary?: Dictionary;
    navigation: NavigationLink[];
    contactNavigation: NavigationLink[];
    scrollDirection?: ScrollDirection;
    scrollPosition?: number;
    hasScrolled?: boolean;
};

export const HeaderContext = createContext<HeaderContextProps>({
    navigation: [],
    contactNavigation: [],
});

export const HeaderContextProvider = ({
    locale = envConfig.NEXT_PUBLIC_APP_LOCALE,
    leftLinksCollection,
    stores,
    services,
    children,
    dictionary,
    navigation,
    contactNavigation,
}: PropsWithChildren<HeaderContextProps>) => {
    const { scrollDirection, scrollPosition } = useScroll();
    const hasScrolled = useMemo(() => scrollPosition > 120, [scrollPosition]);

    const state = useMemo(
        () => ({
            locale,
            leftLinksCollection,
            stores,
            services,
            dictionary,
            navigation,
            contactNavigation,
            scrollDirection,
            scrollPosition,
            hasScrolled,
        }),
        [
            locale,
            leftLinksCollection,
            stores,
            services,
            dictionary,
            navigation,
            contactNavigation,
            scrollDirection,
            scrollPosition,
            hasScrolled,
        ],
    );
    return (
        <HeaderContext.Provider value={state}>
            {children}
        </HeaderContext.Provider>
    );
};

export const useHeaderContext = () => {
    const context = useContext(HeaderContext);

    if (context === undefined) {
        throw new Error(
            'useHeaderContext must be used within a HeaderContextProvider',
        );
    }

    return context;
};
