'use client';

import RichText from '@blocks/RichText';
import Link from '@components/Link';
import { Disclosure } from '@headlessui/react';
import { ArrowIcon, MinusIcon, PlusIcon } from '@ngg/icons';
import { useState } from 'react';

import { FEATURE_FLAGS } from '@/config/featureFlags';
// import { PageTextBlockFragment } from '@server/gql/graphql';
import { cn } from '@/lib/utils';

export default function FooterTextBlock({
    data,
    market,
    locale,
}: {
    data?: /* PageTextBlockFragment */ any;
    market: string;
    locale: string | undefined;
}) {
    const [open, setOpen] = useState(true);

    return (
        <Disclosure as="div">
            <h3 className="text-sm">
                <Disclosure.Button
                    onClick={() => setOpen(!open)}
                    className="flex w-full items-center justify-between px-3 py-4 font-bold tracking-wide sm:hidden">
                    {data?.title}
                    <span className="text-base sm:hidden">
                        {open && <MinusIcon />}
                        {!open && <PlusIcon />}
                    </span>
                </Disclosure.Button>

                <span className="hidden px-3 pt-4 font-medium tracking-wide sm:block sm:pb-3">
                    {data?.title}
                </span>
            </h3>

            <Disclosure.Panel
                static
                className={cn(
                    'max-w-auto px-3 pb-4 text-sm sm:max-w-80',
                    open ? 'block' : 'hidden sm:block',
                )}>
                <RichText
                    content={data?.content}
                    className="prose-sm leading-5 prose-p:m-0 prose-a:font-normal"
                    locale={locale}
                    market={market}
                />

                {(FEATURE_FLAGS.customerService.phoneLink &&
                    data?.phoneNumber) ||
                (FEATURE_FLAGS.customerService.emailLink && data?.email) ? (
                    <div className="mt-6">
                        {FEATURE_FLAGS.customerService.phoneLink &&
                        data?.phoneNumber ? (
                            <p>
                                <a
                                    className="underline"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`tel:${data.phoneNumber}`}>
                                    {data.phoneNumber}
                                </a>
                            </p>
                        ) : null}
                        {FEATURE_FLAGS.customerService.emailLink &&
                        data?.email ? (
                            <p>
                                <a
                                    className="underline"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`mailto:${data.email}`}>
                                    {data.email}
                                </a>
                            </p>
                        ) : null}
                    </div>
                ) : null}

                {data?.pageLink?.slug ? (
                    <div className="mt-6">
                        <Link
                            locale={locale}
                            href={data?.pageLink?.slug}
                            className={cn(
                                data?.type === 'Accordion'
                                    ? 'btn-outlined flex items-center justify-center'
                                    : 'underline',
                            )}>
                            <p>{data?.linkText}</p>
                            <ArrowIcon className="ml-1 text-lg" />
                        </Link>
                    </div>
                ) : null}

                <RichText
                    locale={locale}
                    market={market}
                    content={data?.openingHours}
                    className="prose-sm mt-6 leading-5 prose-p:m-0 prose-a:font-normal"
                />
            </Disclosure.Panel>
        </Disclosure>
    );
}
