'use client';

import { Video } from '@components/Video';
import type { ContentfulAssetFragment, Maybe } from '@server/gql/graphql';
import type { ComponentPropsWithoutRef } from 'react';
import { useCallback, useLayoutEffect, useState } from 'react';

import { breakpoints } from '@/config/breakpoints';

type ContentfulVideoProps = ComponentPropsWithoutRef<'video'> & {
    mobileVideo?: Maybe<ContentfulAssetFragment>;
    desktopVideo?: Maybe<ContentfulAssetFragment>;
};

const ContentfulVideo = ({
    desktopVideo,
    mobileVideo,
    ...props
}: ContentfulVideoProps) => {
    const [isMobile, setIsMobile] = useState<boolean | null>(null);
    const mobileVideoSrc = extractVideoSrc(mobileVideo);
    const desktopVideoSrc = extractVideoSrc(desktopVideo);

    const handleResize = useCallback((entries: ResizeObserverEntry[]) => {
        const [entry] = entries;

        setIsMobile(entry.contentRect.width < breakpoints.lg);
    }, []);

    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize);

        if (document.body) {
            resizeObserver.observe(document.body);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [handleResize]);

    if (isMobile && mobileVideoSrc) {
        return <Video src={mobileVideoSrc} {...props} />;
    }

    if (desktopVideoSrc) {
        return <Video src={desktopVideoSrc} {...props} />;
    }

    return null;
};

ContentfulVideo.displayName = 'ContentfulVideo';

export default ContentfulVideo;

function extractVideoSrc(video?: Maybe<ContentfulAssetFragment>) {
    return video?.url;
}
