'use client';

import { useHeaderContext } from '@app/_context/headerContext';
import type { ReactNode } from 'react';
import { createContext, useMemo } from 'react';

import useWindowSize from '@/utils/hooks/useWindowSize';

type HeaderStateProps = {
    scrollPosition: number;
    scrollDirection: 'up' | 'down' | 'none';
    hasScrolled: boolean;
};

export const HeaderContext = createContext<HeaderStateProps | null>(null);

const MOBILE_USP_BAR_HEIGHT = '24px';
const DESKTOP_USP_BAR_HEIGHT = '32px';
const MENU_BAR_HEIGHT = '32px';

export default function HeaderWrapper({ children }: { children: ReactNode }) {
    const { scrollDirection, hasScrolled } = useHeaderContext();
    const { width: windowWidth } = useWindowSize('lg');
    const uspBarHeight = useMemo(
        () =>
            windowWidth > 1024 ? DESKTOP_USP_BAR_HEIGHT : MOBILE_USP_BAR_HEIGHT,
        [windowWidth],
    );

    return (
        <header
            style={
                {
                    '--header-content-top':
                        hasScrolled && scrollDirection === 'down'
                            ? uspBarHeight
                            : '0px',
                    '--header-menu-top':
                        hasScrolled && scrollDirection === 'down'
                            ? MENU_BAR_HEIGHT
                            : '0px',
                } as React.CSSProperties
            }
            className="sticky top-0 z-30 -translate-y-[var(--header-content-top)] transition-transform duration-200 lg:pointer-events-none">
            {children}
        </header>
    );
}
