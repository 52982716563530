'use client';

import { useSiteInfo } from '@context/siteInfoContext';
import type {
    ExceptionalOpeningHoursDay,
    OpeningHoursDay,
    Store as IStore,
} from '@server/gql/graphql';
import type { HTMLAttributes } from 'react';

import { FEATURE_FLAGS } from '@/config/featureFlags';
import { cn } from '@/lib/utils';
import {
    getIrregularOpeningHoursDisplayData,
    getOpeningHoursDisplayData,
} from '@/utils/common';

import RichText from '../RichText';

type StoreProps = IStore & {
    market: string;
    locale: string | undefined;
} & HTMLAttributes<HTMLDivElement>;

const Store = (props: StoreProps) => {
    const {
        title,
        className,
        address,
        postalCode,
        city,
        phoneNumber,
        email,
        description,
        openingHoursCollection,
        exceptionalOpeningHoursCollection,
        market,
        locale,
        googleMapsUrl,
    } = props;
    const { globalDictionary } = useSiteInfo();

    const openingHoursData = openingHoursCollection?.items as OpeningHoursDay[];
    const irregularOpeningHours = exceptionalOpeningHoursCollection?.items as
        | ExceptionalOpeningHoursDay[]
        | undefined;
    const irregularDisplayData = irregularOpeningHours
        ? getIrregularOpeningHoursDisplayData(
              irregularOpeningHours,
              globalDictionary,
          )
        : null;
    const displayData = getOpeningHoursDisplayData(openingHoursData);

    return (
        <div className={cn('not-prose', className)}>
            <h3 className="text-xl font-bold">{title}</h3>
            {description && (
                <RichText
                    locale={locale}
                    market={market}
                    content={description}
                    className="mb-6 ml-0 mt-3 max-w-120 space-y-3"
                />
            )}

            <div className="mt-3 grid grid-cols-2 gap-x-[clamp(1.5rem,_11%,_3.5rem)] gap-y-3">
                {displayData?.length ? (
                    <div>
                        <h4 className="mb-1 text-base font-bold">
                            {globalDictionary?.openingHours}
                        </h4>
                        {displayData.map((item) => (
                            <p
                                key={
                                    item?.text
                                }>{`${item?.text}: ${item?.time}`}</p>
                        ))}
                    </div>
                ) : null}

                {address || (postalCode && city) ? (
                    <div>
                        {address ? (
                            <>
                                <h4 className="mb-1 text-base font-bold">
                                    {globalDictionary?.address}
                                </h4>
                                <p>{address}</p>
                            </>
                        ) : null}
                        {postalCode && city ? (
                            <p>
                                {postalCode}, {city}
                            </p>
                        ) : null}
                    </div>
                ) : null}

                {irregularDisplayData?.length ? (
                    <div>
                        <h4 className="mb-1 text-base font-bold">
                            {globalDictionary?.irregularOpeningHours}
                        </h4>
                        {irregularDisplayData.map((item) => (
                            <p
                                key={
                                    item?.text
                                }>{`${item?.text}: ${item?.time}`}</p>
                        ))}
                    </div>
                ) : null}

                {(FEATURE_FLAGS.customerService.phoneLink && phoneNumber) ||
                    (FEATURE_FLAGS.customerService.emailLink && email && (
                        <div className="break-words">
                            <h4 className="mb-1 text-base font-bold">
                                {globalDictionary?.contact}
                            </h4>
                            {FEATURE_FLAGS.customerService.phoneLink &&
                                phoneNumber && <p>{phoneNumber}</p>}
                            {FEATURE_FLAGS.customerService.emailLink &&
                                email && (
                                    <p>
                                        <a
                                            href={`mailto:${email}`}
                                            className="hover:underline">
                                            {email}
                                        </a>
                                    </p>
                                )}
                        </div>
                    ))}

                {googleMapsUrl ? (
                    <div className="self-end">
                        <a
                            target="_blank"
                            rel="noreferrer nofollow"
                            href={googleMapsUrl}
                            className="font-bold underline">
                            {globalDictionary.findHere || 'Find us'}
                        </a>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Store;
