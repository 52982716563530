import type { Variants } from 'framer-motion';
import { useEffect, useState } from 'react';

import { TRANSITION_EASE_OUT } from '@/config/transitions';

const TOPBAR_USP_ANIMATION_DELAY = 3000; // 3 seconds

export const uspAnimationVariants: Variants = {
    enter: () => {
        return {
            y: -12,
            opacity: 0,
            transition: TRANSITION_EASE_OUT,
        };
    },
    center: {
        y: 0,
        opacity: 1,
        transition: TRANSITION_EASE_OUT,
    },
    exit: () => {
        return {
            y: 12,
            opacity: 0,
            transition: TRANSITION_EASE_OUT,
        };
    },
};

export const useUspAnimation = ({
    enabled = true,
    total,
}: {
    enabled?: boolean;
    total: number;
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isAnimationPlaying, setIsAnimationPlaying] = useState(true);

    useEffect(() => {
        if (enabled) {
            const interval = setInterval(() => {
                setCurrentSlide((prev) => (prev + 1) % total);
            }, TOPBAR_USP_ANIMATION_DELAY);

            if (!isAnimationPlaying) {
                clearInterval(interval);
            }

            return () => clearInterval(interval);
        }

        return () => {};
    }, [enabled, total, currentSlide, setCurrentSlide, isAnimationPlaying]);

    return {
        currentSlide,
        setIsAnimationPlaying,
    };
};
