export const SWEDISH_TRUSTPILOT_LOCALES = ['sv-se', 'sv', 'se'];
export const NORWEGIAN_TRUSTPILOT_LOCALES = ['nb-no', 'nb', 'no'];
export const FINNISH_TRUSTPILOT_LOCALES = ['fi', 'fi-fi'];
export const DANISH_TRUSTPILOT_LOCALES = ['dk', 'da-dk', 'da'];

export const TRUSTPILOT_LOCALES = [
    ...SWEDISH_TRUSTPILOT_LOCALES,
    ...NORWEGIAN_TRUSTPILOT_LOCALES,
    ...FINNISH_TRUSTPILOT_LOCALES,
    ...DANISH_TRUSTPILOT_LOCALES,
];
