'use client';

import { parseOptions } from '@blocks/RichText/RichText';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { AccordionProps } from '@ngg/components';
import { Accordion as NGGAccordion } from '@ngg/components';
import { MinusIcon, PlusIcon } from '@ngg/icons';
import type { Links } from 'types/contentfulRichText';

import { cn } from '@/lib/utils';

const Icon = ({ open }: any) => (
    <span>
        {open ? (
            <MinusIcon className="text-base" />
        ) : (
            <PlusIcon className="text-base" />
        )}
    </span>
);

type Props = AccordionProps & {
    text?: { links: Links; json: any };
    richText?: boolean;
};

export default function Accordion({
    children,
    text,
    richText,
    ...props
}: Props) {
    let content = children;
    if (richText && text) {
        content = documentToReactComponents(
            text.json,
            parseOptions({ content: { links: text.links }, ...(props as any) }),
        );
    }
    return (
        <NGGAccordion
            {...props}
            IconComponent={Icon}
            WrapperComponent="div"
            classNames={{
                wrapper: cn(
                    'ngg-accordion bg-white',
                    props.classNames?.wrapper,
                ),
                button: cn(
                    'items-center px-3 py-5 text-left font-bold leading-4',
                    props.classNames?.button,
                ),
                panel: cn('px-3 pb-5 pt-0', props.classNames?.panel),
            }}>
            {content}
        </NGGAccordion>
    );
}
