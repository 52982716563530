'use client';

import { useEffect, useState } from 'react';

import hasTrustpilot from '@/utils/trustpilotUtils';

import TrustpilotWidget from './trustpilotWidget';

declare global {
    interface Window {
        Trustpilot: any;
    }
}

export default function Trustpilot({ locale }: { locale: string | undefined }) {
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!locale || !hasTrustpilot(locale)) {
            return;
        }

        const el = document.createElement('script');

        el.type = 'text/javascript';
        el.src =
            '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        el.async = true;

        document.head.appendChild(el);

        el.onload = () => {
            setLoading(false);

            window.Trustpilot.loadFromElement(
                document.getElementById('trustbox'),
            );
        };
    }, [locale]);

    if (!locale || !hasTrustpilot(locale)) {
        return null;
    }

    return (
        <div className="overflow-hidden border-b border-grey-150 py-10">
            <TrustpilotWidget locale={locale}></TrustpilotWidget>
            {loading ? (
                <div className="grid grid-cols-5 gap-9 px-10">
                    <div className="flex animate-pulse flex-col">
                        <div className="h-4 w-full bg-gray-100" />
                        <div className="mt-1 h-4 w-20 bg-gray-100" />
                        <div className="mt-1 h-8 bg-gray-50" />
                        <div className="mt-1 h-4 w-40 bg-gray-50" />
                        <div className="mt-1 h-4 w-20 bg-gray-50" />
                    </div>
                    <div className="flex animate-pulse flex-col">
                        <div className="h-4 w-full bg-gray-100" />
                        <div className="mt-1 h-4 w-20 bg-gray-100" />
                        <div className="mt-1 h-8 bg-gray-50" />
                        <div className="mt-1 h-4 w-40 bg-gray-50" />
                        <div className="mt-1 h-4 w-20 bg-gray-50" />
                    </div>
                    <div className="flex animate-pulse flex-col">
                        <div className="h-4 w-full bg-gray-100" />
                        <div className="mt-1 h-4 w-20 bg-gray-100" />
                        <div className="mt-1 h-8 bg-gray-50" />
                        <div className="mt-1 h-4 w-40 bg-gray-50" />
                        <div className="mt-1 h-4 w-20 bg-gray-50" />
                    </div>
                    <div className="flex animate-pulse flex-col">
                        <div className="h-4 w-full bg-gray-100" />
                        <div className="mt-1 h-4 w-20 bg-gray-100" />
                        <div className="mt-1 h-8 bg-gray-50" />
                        <div className="mt-1 h-4 w-40 bg-gray-50" />
                        <div className="mt-1 h-4 w-20 bg-gray-50" />
                    </div>
                    <div className="flex animate-pulse flex-col">
                        <div className="h-4 w-full bg-gray-100" />
                        <div className="mt-1 h-4 w-20 bg-gray-100" />
                        <div className="mt-1 h-8 bg-gray-50" />
                        <div className="mt-1 h-4 w-40 bg-gray-50" />
                        <div className="mt-1 h-4 w-20 bg-gray-50" />
                    </div>
                    <div className="col-span-5 flex animate-pulse flex-col items-center">
                        <div className="h-4 w-96 bg-gray-100" />
                        <div className="mt-1 h-4 w-40 bg-gray-100" />
                    </div>
                </div>
            ) : null}
        </div>
    );
}
