import type { NavigationLink } from '@components/header/types';

export const getInitialNavigationTree = ({
    slug,
    links,
}: {
    slug: string;
    links?: NavigationLink[];
}) => {
    if (!links) return [];
    const root: NavigationLink = {
        slug: '',
        type: 'Root',
        title: 'Root',
        children: links,
    };
    const recursiveResult = getNavigationTreeRecursive(slug, [root], links);
    if (recursiveResult.length > 0) {
        return recursiveResult;
    }
    return [root];
};

const getNavigationTreeRecursive = (
    slug: string,
    previousLinks: NavigationLink[],
    nextLinks: NavigationLink[],
): NavigationLink[] => {
    for (let i = 0; i < nextLinks.length; i++) {
        const link = nextLinks.at(i);
        if (link === null || link === undefined) {
            continue;
        }
        if (link.slug === slug) {
            return [...previousLinks];
        }
        if (!link.children?.length) {
            continue;
        }
        const recursiveResult = getNavigationTreeRecursive(
            slug,
            [...previousLinks, link],
            link.children,
        );
        if (recursiveResult.length > 0) {
            return recursiveResult;
        }
    }
    return [];
};
