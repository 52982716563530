import type { PropsWithChildren } from 'react';
import { useRef } from 'react';

import { cn } from '@/lib/utils';

import Context from './Context';
import Input from './Input';
import List from './List';
import Suggestion from './Suggestion';

export interface SearchProps extends PropsWithChildren {
    onSearch: (event: string) => void;
    onChange?: (event: string | null) => void;
    className?: string;
}

const Search = ({
    children,
    className,
    onChange = () => null,
    onSearch = () => null,
    ...props
}: SearchProps) => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <Context wrapperRef={ref} onSearch={onSearch} onChange={onChange}>
            <div
                ref={ref}
                className={cn('group relative bg-white', className)}
                {...props}>
                {children}
            </div>
        </Context>
    );
};

const composedSearch = {
    Root: Search,
    List,
    Input,
    Suggestion,
};

export default composedSearch;
