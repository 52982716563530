import type { ImageProps } from '@components/Image';
import Image from '@components/Image';
import type { ContentfulAssetFragment, Maybe } from '@server/gql/graphql';

import { cn } from '@/lib/utils';

type ContentfulImageProps = {
    mobileImage?: Maybe<ContentfulAssetFragment>;
    desktopImage?: Maybe<ContentfulAssetFragment>;
} & Omit<ImageProps, 'src' | 'alt'>;

export default function ContentfulImage({
    className,
    mobileImage,
    desktopImage,
    sizes,
    priority,
    ...props
}: ContentfulImageProps) {
    return (
        <>
            {desktopImage?.url && (
                <Image
                    className={cn(
                        mobileImage?.url && 'max-lg:hidden',
                        className,
                    )}
                    src={desktopImage.url}
                    alt={desktopImage.title ?? ''}
                    width={desktopImage.width ?? undefined}
                    height={desktopImage.height ?? undefined}
                    sizes={sizes}
                    priority={priority}
                    {...props}
                />
            )}
            {mobileImage?.url && (
                <Image
                    className={cn('lg:hidden', className)}
                    src={mobileImage.url}
                    alt={mobileImage.title ?? ''}
                    width={mobileImage.width ?? undefined}
                    height={mobileImage.height ?? undefined}
                    priority={priority}
                    {...props}
                />
            )}
        </>
    );
}
