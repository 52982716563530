import { envConfig } from '@/config/env';

export function getSearchLocale(locale?: string) {
    if (envConfig.NEXT_PUBLIC_APP_LOCALE !== undefined) {
        return '';
    }

    if (locale) {
        return `/${locale}`;
    }

    return '';
}
