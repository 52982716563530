import LinkWithLocale from '@components/Link';
import Link from 'next/link';
import type { HTMLAttributes } from 'react';

type RichTextLinkProps = HTMLAttributes<HTMLAnchorElement> & {
    locale?: string;
    href: string;
};

const RichTextLink = ({
    children,
    href,
    locale,
    ...props
}: RichTextLinkProps) => {
    const isExternalLink = href.includes('no-ga.com') === false;
    const isAbsoluteLink = href.includes('http') === true;

    if (isExternalLink && isAbsoluteLink) {
        return (
            <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
                {children}
            </a>
        );
    }

    if (locale) {
        <LinkWithLocale locale={locale} href={href} />;
    }

    return <Link href={href}>{children}</Link>;
};

RichTextLink.displayName = 'RichTextLink';

export default RichTextLink;
