import type { ComponentPropsWithoutRef } from 'react';
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import { cn } from '@/lib/utils';

type VideoProps = ComponentPropsWithoutRef<'video'>;

const Video = forwardRef<HTMLVideoElement | null, VideoProps>(
    ({ className, src, autoPlay = true, ...props }, ref) => {
        const [isBatteryLow, setBatteryLow] = useState(false);

        const videoRef = useRef<HTMLVideoElement | null>(null);
        useImperativeHandle(ref, () => videoRef.current as HTMLVideoElement);
        useEffect(() => {
            if (videoRef.current && autoPlay) {
                const promise = videoRef.current.play();
                if (promise !== undefined) {
                    promise.catch((error) => {
                        if (error.name === 'NotAllowedError') {
                            setBatteryLow(true);
                        }
                    });
                }
            }
        }, [autoPlay]);
        if (isBatteryLow) {
            return null;
        }
        return (
            <video
                ref={videoRef}
                className={cn('h-full w-full object-cover', className)}
                src={src}
                autoPlay={autoPlay}
                playsInline
                loop
                muted
                {...props}
            />
        );
    },
);

Video.displayName = 'Video';

export default Video;
