'use client';

import type { HeaderFragment } from '@server/gql/graphql';
import type { Dispatch, HTMLAttributes, SetStateAction } from 'react';
import {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

const TOPBAR_MOBILE_BREAKPOINT = 1024;

type TopbarContextProps = {
    isMobile: boolean | null;
    setIsMobile: Dispatch<SetStateAction<boolean | null>>;
    uspCollection?: HeaderFragment['uspCollection'];
};

export const TopbarContext = createContext<TopbarContextProps>({
    isMobile: null,
    setIsMobile: () => {},
});

type TopbarProviderProps = HTMLAttributes<HTMLDivElement> & {
    uspCollection?: HeaderFragment['uspCollection'];
};

export const TopbarProvider = ({
    children,
    uspCollection,
    ...props
}: TopbarProviderProps) => {
    const [isMobile, setIsMobile] = useState<boolean | null>(null);
    const ref = useRef<HTMLDivElement | null>(null);

    function handleResize(entries: ResizeObserverEntry[]) {
        const [entry] = entries;

        setIsMobile(entry.contentRect.width < TOPBAR_MOBILE_BREAKPOINT);
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize);

        if (ref?.current instanceof HTMLDivElement) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const value = useMemo(
        () => ({
            isMobile,
            setIsMobile,
            uspCollection,
        }),
        [isMobile, setIsMobile, uspCollection],
    );

    return (
        <TopbarContext.Provider value={value}>
            <div ref={ref} {...props}>
                {children}
            </div>
        </TopbarContext.Provider>
    );
};

export const useTopbarContext = () => {
    const context = useContext(TopbarContext);

    if (context === undefined) {
        throw new Error(
            'useTopbarContext must be used within a TopbarContextProvider',
        );
    }

    return context;
};
