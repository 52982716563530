'use client';

import { useRouter } from 'next/navigation';
import type { FormEvent } from 'react';

export default function PreviewBanner() {
    const router = useRouter();

    function disablePreview(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        return fetch(`/api/disable-draft`, {
            method: 'GET',
        })
            .then(() => router.refresh())
            .catch((error) => console.error(error));
    }

    return (
        <form
            onSubmit={disablePreview}
            className="flex items-center justify-center gap-x-1 bg-black p-0.5 text-center text-xxs uppercase tracking-wide text-white">
            Preview enabled
            <button type="submit" className="uppercase tracking-wide underline">
                Disable
            </button>
        </form>
    );
}
