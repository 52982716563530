import { TRUSTPILOT_LOCALES } from '@/config/trustpilot';

export interface TrustpilotConfig {
    locale: string;
    businessunitId: string;
    href: string;
}

export default function hasTrustpilot(locale: string): boolean {
    return TRUSTPILOT_LOCALES.includes(locale.toLowerCase());
}
