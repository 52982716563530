/* eslint-disable @next/next/no-img-element */

import type { ComponentPropsWithRef } from 'react';
import { forwardRef } from 'react';

import { cn } from '@/lib/utils';
import { getImageFormat } from '@/utils/getImageFormat';

export type ImageProps = ComponentPropsWithRef<'img'> & {
    src: string;
    alt: string;
    sizes?: string;
    width?: number;
    height?: number;
    priority?: boolean;
    lazyload?: boolean;
    widths?: number[];
    quality?: number;
};

const DEFAULT_IMAGE_WIDTHS = [640, 750, 828, 1080, 1200, 1920, 2048, 3840];
const DEFAULT_IMAGE_QUALITY = 80;

function useSrcSet({
    widths,
    src,
    quality,
}: Pick<ImageProps, 'src' | 'widths' | 'quality'>) {
    // If there are no widths or if we only got one width, then we dont need a srcset.
    // Return undefiend and fallback to the default src.
    if (!widths || widths.length <= 1) {
        return undefined;
    }

    // If we have multiple widths, we generate a srcset.
    return widths
        .map(
            (width) =>
                `${src}?w=${width}&q=${quality}${getImageFormat(src)} ${width}w`,
        )
        .join(', ');
}

function useDefaultSrc({
    src,
    widths,
    quality,
}: Pick<ImageProps, 'src' | 'widths' | 'quality'>) {
    // If there are no widths, just return the original src
    if (!widths || widths.length == 0) {
        return src;
    }

    // Find the largest width
    const largestWidth = Math.max(...widths);

    // If we have widths, we return a default src
    return `${src}?w=${largestWidth}&q=${quality}${getImageFormat(src)}`;
}

const Image = forwardRef<HTMLImageElement, ImageProps>(
    (
        {
            className,
            src,
            alt,
            widths = DEFAULT_IMAGE_WIDTHS,
            quality = DEFAULT_IMAGE_QUALITY,
            priority,
            lazyload = true,
            ...props
        },
        ref,
    ) => {
        // Generate the srcset based on the widths
        const srcSet = useSrcSet({ widths, src, quality });

        // Generate the default src with the largest width
        const defaultSrc = useDefaultSrc({ src, widths, quality });

        return (
            <img
                ref={ref}
                srcSet={srcSet}
                src={defaultSrc}
                className={cn('object-cover', className)}
                loading={
                    lazyload === true && priority !== true ? 'lazy' : 'eager'
                }
                alt={alt ?? ''}
                {...props}
            />
        );
    },
);

Image.displayName = 'Image';

export default Image;
