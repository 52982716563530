'use client';

import type {
    ContactNavigationFragment,
    MainNavigationFragment,
    MainNavigationQuery,
    Navigation,
} from '@server/gql/graphql';
import type { PropsWithChildren } from 'react';
import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useReducer,
} from 'react';

import type { MarketAwareSlug } from '@/utils/const';
import flattenNavigation, {
    type FlattenedNavigation,
} from '@/utils/navigation/flattenNavigation';

type InitialNavigationState = {
    data: MainNavigationQuery;
    details: MarketAwareSlug;
};

type NavigationState = {
    details: MarketAwareSlug;
    main?: MainNavigationFragment | null;
    contact?: ContactNavigationFragment | null;
    commerceNamePairs?: Record<string, string>;
    commerceSlugPairs?: Record<string, string>;
    flatNavigation?: FlattenedNavigation;
};

type NavigationProps = {
    state: NavigationState;
    onOpenMenu: (id: string) => void;
};

type NavigationAction = {
    type: 'OPEN_MENU';
    payload: { id: string };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function reducer(state: NavigationState, action: NavigationAction) {
    return state;
}

function initiateState(state: InitialNavigationState): NavigationState {
    const { data, details } = state;
    const navigation = data.headerCollection?.items.at(0);

    if (!navigation) {
        return { details };
    }

    const { navigation: main, contactNavigation: contact } = navigation;
    const flatNavigation = flattenNavigation(main as Navigation);
    const categoryKeys = Object.keys(flatNavigation);

    const commerceNamePairs: Record<string, string> = {};
    const commerceSlugPairs: Record<string, string> = {};

    for (let i = 0; i < categoryKeys.length; i += 1) {
        const category = flatNavigation[categoryKeys[i]];

        if (category.commerceId && category.title) {
            commerceNamePairs[category.commerceId] = category.title;
        }

        if (category.commerceId && category.slug) {
            commerceSlugPairs[category.commerceId] = category.slug;
        }
    }

    return {
        details,
        main,
        contact,
        flatNavigation,
        commerceNamePairs,
        commerceSlugPairs,
    };
}

const NavigationContext = createContext<NavigationProps | null>(null);

export const NavigationProvider = ({
    children,
    ...initialState
}: PropsWithChildren<InitialNavigationState>) => {
    const [state, dispatch] = useReducer(reducer, null, () =>
        initiateState(initialState),
    );

    const onOpenMenu = useCallback((id: string) => {
        dispatch({ type: 'OPEN_MENU', payload: { id } });
    }, []);

    const value = useMemo(
        () => ({
            state,
            onOpenMenu,
        }),
        [state, onOpenMenu],
    );

    return (
        <NavigationContext.Provider value={value}>
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavigation = () => {
    const context = useContext(NavigationContext);
    if (context === undefined)
        throw new Error(
            'useNavigation must be used within a NavigationProvider',
        );
    return context as NavigationProps;
};
