import { useSearchParams } from 'next/navigation';
import type {
    Dispatch,
    MutableRefObject,
    ReactNode,
    SetStateAction,
} from 'react';
import { createContext, useMemo, useRef, useState } from 'react';
import type { Dictionary } from 'types';

export type SearchContextProps = {
    locale?: string;
    dictionary?: Dictionary;
    formRef?: MutableRefObject<HTMLFormElement | null>;
    inputRef?: MutableRefObject<HTMLInputElement | null>;
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
};

export const SearchContext = createContext<SearchContextProps>({
    value: '',
    setValue: () => {},
});

export type SearchProviderProps = {
    children: ReactNode;
    locale?: string;
    dictionary?: Dictionary;
};

export const SearchProvider = ({
    children,
    locale,
    dictionary,
}: SearchProviderProps) => {
    const params = useSearchParams();
    const formRef = useRef<HTMLFormElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [value, setValue] = useState(params.get('query') ?? '');

    const context = useMemo(
        () => ({
            locale,
            dictionary,
            formRef,
            inputRef,
            value,
            setValue,
        }),
        [locale, dictionary, formRef, inputRef, value, setValue],
    );

    return (
        <SearchContext.Provider value={context}>
            {children}
        </SearchContext.Provider>
    );
};
