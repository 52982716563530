'use client';

import { useMemo } from 'react';

type AttributesMap = Record<string, string>;

/**
 * Matches property declaration in a string, for example:
 * <iframe src="some-source" id="some-id" width="100%" />
 * will return [["src", "some-source"], ["id", "some-id"], ["width", "100%"]]
 */
const regex = /(\b[^=\s]+\b)=["{]([^"}]+)[}"]/gs;

export default function Frame(props: { text: string }) {
    const { text } = props;
    const opts = useMemo(() => {
        const matches = text.matchAll(regex);
        const attributesObject: AttributesMap = {};
        for (const [, attributeName, attributeValue] of matches) {
            attributesObject[attributeName] = attributeValue;
        }
        return attributesObject;
    }, [text]);
    if (!opts) return null;
    return <iframe {...opts} />;
}
