import type { HTMLProps, MouseEvent } from 'react';
import { useEffect, useRef } from 'react';

import { cn } from '@/lib/utils';

import { useSearch } from './Context';
import usePointer from './usePointer';

type SuggestionProps = HTMLProps<HTMLLIElement> & {
    value: string;
};

const SearchSuggestion = ({
    value,
    disabled,
    children,
    className,
    ...props
}: SuggestionProps) => {
    const ref = useRef<HTMLLIElement>(null);
    const { observer, selected, setSelected, onClick } = useSearch();

    const pointer = usePointer();

    const handleEnter = (event: MouseEvent) => {
        pointer.update(event);
        if (value) setSelected(value);
    };

    const handleMove = (event: MouseEvent) => {
        if (!pointer.wasMoved(event)) return;
        if (disabled) return;
        setSelected(value);
    };

    const handleLeave = (event: MouseEvent) => {
        if (!pointer.wasMoved(event)) return;
        if (disabled) return;
        setSelected(null);
    };

    useEffect(() => {
        if (selected === value && ref?.current)
            observer?.observe?.(ref.current);
        return () => observer?.disconnect?.();
    }, [value, selected, observer]);

    return (
        <li
            ref={ref}
            onClick={onClick}
            onMouseMove={handleMove}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            role="option"
            value={value}
            aria-disabled={disabled}
            aria-selected={selected === value}
            className={cn(
                'cursor-pointer select-none px-8 py-2 aria-disabled:pointer-events-none aria-disabled:opacity-30 aria-selected:bg-gray-100',
                className,
            )}
            {...props}>
            {children}
        </li>
    );
};

SearchSuggestion.displayName = 'SearchSuggestion';

export default SearchSuggestion;
