import type { OpeningTime } from './getOpeningHours';

export default function OpeningHour({
    item,
    closedTitle,
}: {
    item: OpeningTime;
    closedTitle?: string;
}) {
    return (
        <p className="text-nowrap" key={item.days.at(0)}>
            {item.days.length >= 2 ? (
                <>
                    {item.days.at(0)}&nbsp;-&nbsp;{item.days.at(-1)}
                </>
            ) : (
                item.days.at(0)
            )}
            :&nbsp;
            {item.open ? (
                <>
                    {item.opening}&nbsp;-&nbsp;{item.closing}
                </>
            ) : (
                closedTitle
            )}
        </p>
    );
}
