import { Button } from '@components/ui';
import type { ComponentPropsWithoutRef, MouseEvent } from 'react';
import { startTransition } from 'react';
import { useFormStatus } from 'react-dom';

import { useMenuStore } from '@/stores/menuStore';

type SearchSubmitProps = ComponentPropsWithoutRef<'button'>;

const SearchSubmit = ({ children, ...props }: SearchSubmitProps) => {
    const { pending } = useFormStatus();
    const setMobileMenuOpen = useMenuStore((state) => state.setMobileMenuOpen);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        startTransition(() => {
            setMobileMenuOpen(false);
        });
    };

    return (
        <Button
            disabled={pending}
            onClick={handleClick}
            type="submit"
            {...props}>
            {children}
        </Button>
    );
};

SearchSubmit.displayName = 'SearchSubmit';

export default SearchSubmit;
