import type { ContentfulAssetFragment, Maybe } from '@server/gql/graphql';
import { getImageProps } from 'next/image';

export function getContentfulImageProps(
    image?: Maybe<ContentfulAssetFragment>,
) {
    if (typeof image?.url !== 'string') {
        return undefined;
    }

    const src = image.url;
    const alt = image?.title ?? '';

    const common = {
        sizes: '100vw',
    };

    const imageProps = getImageProps({
        src,
        alt,
        width: image.width ?? undefined,
        height: image.height ?? undefined,
        ...common,
    });

    return imageProps.props;
}
