'use client';

import Link from '@components/Link';
import { Disclosure } from '@headlessui/react';
import { ArrowIcon, MinusIcon, PlusIcon } from '@ngg/icons';
import type {
    ExceptionalOpeningHoursDay,
    LayoutStoreFragment,
    OpeningHoursDay,
} from '@server/gql/graphql';
import type { Dictionary } from 'types/common';

import { FEATURE_FLAGS } from '@/config/featureFlags';
import { cn } from '@/lib/utils';
import {
    getIrregularOpeningHoursDisplayData,
    getOpeningHoursDisplayData,
} from '@/utils/common';

export default function FooterStore({
    data,
    dictionary,
    locale,
}: {
    data: LayoutStoreFragment;
    dictionary: Dictionary;
    locale: string | undefined;
}) {
    const openingHoursData = data?.openingHoursCollection
        ?.items as OpeningHoursDay[];
    const irregularOpeningHours = data?.exceptionalOpeningHoursCollection
        ?.items as ExceptionalOpeningHoursDay[] | undefined;

    const irregularDisplayData = irregularOpeningHours
        ? getIrregularOpeningHoursDisplayData(irregularOpeningHours, dictionary)
        : null;
    const displayData = getOpeningHoursDisplayData(openingHoursData);

    return (
        <Disclosure defaultOpen={data?.type === 'Online'} as="div">
            {({ open }) => (
                <>
                    <h3 className="text-sm">
                        <Disclosure.Button className="flex w-full items-center justify-between px-3 pb-4 pt-4 font-medium tracking-wide sm:hidden">
                            {data?.title}
                            <span className="text-base sm:hidden">
                                {open && <MinusIcon />}
                                {!open && <PlusIcon />}
                            </span>
                        </Disclosure.Button>

                        <span className="hidden px-3 pt-4 font-medium tracking-wide sm:block sm:pb-3">
                            {data?.title}
                        </span>
                    </h3>

                    <Disclosure.Panel
                        static
                        className={cn(
                            ' px-3 pb-4 text-sm',
                            open ? 'block' : 'hidden sm:block',
                        )}>
                        <div>
                            {data?.infoText ? <p>{data.infoText}</p> : null}
                            {data?.type === 'Store' ? (
                                <div>
                                    {data?.address ? (
                                        <p>{data.address}</p>
                                    ) : null}
                                    {data?.postalCode && data.city ? (
                                        <p>{`${data.postalCode} ${data.city}`}</p>
                                    ) : null}
                                    {data?.storePageLinkText &&
                                    data?.storePage?.slug ? (
                                        <Link
                                            locale={locale}
                                            className="group inline-flex items-center underline"
                                            href={data.storePage.slug}>
                                            {data?.storePageLinkText}
                                            <ArrowIcon className="text-base text-black opacity-0 transition-[opacity,transform] group-hover:translate-x-0.5 group-hover:opacity-100 supports-[hover]:opacity-0" />
                                        </Link>
                                    ) : null}
                                </div>
                            ) : null}
                            <div className="mt-6">
                                {data?.type === 'Online' &&
                                FEATURE_FLAGS.customerService.emailLink &&
                                data?.email ? (
                                    <p>
                                        <a
                                            className="hover:underline"
                                            href={`mailto:${data?.email}`}>
                                            {data.email}
                                        </a>
                                    </p>
                                ) : null}
                            </div>
                            {data?.type === 'Online' &&
                                data?.storePage?.slug &&
                                (dictionary?.contactForm ||
                                    data?.storePageLinkText) && (
                                    <div className="mt-3">
                                        <Link
                                            locale={locale}
                                            className="group inline-flex items-center gap-1 border border-color-base px-3 py-2 transition-colors hover:bg-black hover:text-white"
                                            href={data?.storePage?.slug}>
                                            {data?.storePageLinkText}
                                            <ArrowIcon className="text-base text-current" />
                                        </Link>
                                    </div>
                                )}
                            <div className="mt-6">
                                {dictionary?.openingHours && (
                                    <h3 className="mb-3 font-medium tracking-wide">
                                        {dictionary?.openingHours}
                                    </h3>
                                )}
                                {displayData?.map((item, index) => (
                                    <p key={item?.text + index}>
                                        {item?.text}
                                        {': '}
                                        {item?.time}
                                    </p>
                                ))}
                                {irregularDisplayData?.map((item, index) => (
                                    <p key={item?.text + index}>
                                        {item?.text}
                                        {': '}
                                        {item?.time}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
