'use client';

import { useMarket } from '@app/_context';
import Link from '@components/Link';
import { useHeaderContext } from '@context/headerContext';
import useOutsideClick from '@hooks/useOutsideClick';
import { Button } from '@ngg/components';
import { ArrowDownIcon, ArrowUpIcon } from '@ngg/icons';
import type { HeaderStoreFragment } from '@server/gql/graphql';
import { AnimatePresence, motion } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';

import { cn } from '@/lib/utils';
import { getOpeningHoursText, getStoreOpeningHoursToday } from '@/utils/common';

import getOpeningHours from './getOpeningHours';
import OpeningHour from './OpeningHour';

export default function ServicePopup({
    locale,
    services,
}: {
    locale?: string;
    services: HeaderStoreFragment[];
}) {
    const { dictionary } = useHeaderContext();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [customerServiceToggleOpen, setCustomerServiceToggleOpen] =
        useState(false);
    const ref = useOutsideClick(() => setDropdownOpen(false));
    const pathname = usePathname();
    const { contentfulLocale } = useMarket().state;

    useEffect(() => setDropdownOpen(false), [pathname]);

    const [openingHours, irregularHours] = useMemo(() => {
        const item = services.at(0);
        if (!item) return [[], []];
        return getOpeningHours(item);
    }, [services]);

    const service = services.at(0);

    const currentOpeningHours = useMemo(() => {
        return getOpeningHoursText({
            store: service,
            contentfulLocale,
            openTodayAt: dictionary?.openTodayAt,
            opensTomorrowAt: dictionary?.opensTomorrowAt,
            opensAtDayOfWeekAt: dictionary?.opensAtDayOfWeekAt,
        });
    }, [service, contentfulLocale, dictionary]);

    const customerServiceOpeningHours = useMemo(() => {
        return getStoreOpeningHoursToday({
            store: service,
            contentfulLocale,
        });
    }, [service, contentfulLocale]);

    if (!service) return null;

    return (
        <div className="relative h-full" ref={ref}>
            <Button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className={cn(
                    'relative h-full gap-1 px-2',
                    dropdownOpen ? 'bg-white text-black' : 'bg-inherit',
                )}
                endIcon={
                    dropdownOpen ? (
                        <ArrowUpIcon className="h-3.5 w-3.5" />
                    ) : (
                        <ArrowDownIcon className="h-3.5 w-3.5" />
                    )
                }>
                {service.title} (
                {customerServiceOpeningHours?.isClosed
                    ? dictionary?.customerServiceClosed?.toLocaleLowerCase()
                    : dictionary?.customerServiceOpen?.toLocaleLowerCase()}
                )
            </Button>

            <div
                className={cn(
                    'min-w-92 absolute right-0 top-full z-50 min-w-96 border-x border-b bg-white p-6 space-x-6',
                    dropdownOpen ? 'flex' : 'hidden',
                )}>
                <div className="flex w-full flex-col items-start justify-start">
                    <p className="mb-6 text-base font-normal">
                        {service.infoText}
                    </p>
                    {currentOpeningHours && (
                        <p className="mb-1 text-base font-bold">
                            {currentOpeningHours}
                        </p>
                    )}
                    <AnimatePresence>
                        {customerServiceToggleOpen && (
                            <motion.div
                                className="overflow-hidden"
                                initial={{ height: 0 }}
                                animate={{ height: 'auto' }}
                                exit={{ height: 0 }}>
                                {openingHours.map((item) => (
                                    <OpeningHour
                                        key={item.days.at(0)}
                                        item={item}
                                        closedTitle={dictionary?.closed}
                                    />
                                ))}
                                {irregularHours.map((item) => (
                                    <OpeningHour
                                        key={item.days.at(0)}
                                        item={item}
                                        closedTitle={dictionary?.closed}
                                    />
                                ))}
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <button
                        type="button"
                        onClick={() =>
                            setCustomerServiceToggleOpen((open) => !open)
                        }
                        className={cn(
                            'mb-3 inline-flex items-center gap-1 py-1 underline hover:no-underline',
                        )}>
                        {customerServiceToggleOpen
                            ? dictionary?.closed
                            : dictionary?.openingHours}
                        {customerServiceToggleOpen ? (
                            <ArrowUpIcon />
                        ) : (
                            <ArrowDownIcon />
                        )}
                    </button>
                    <div className="flex w-full flex-col space-y-2">
                        {service.phoneNumber ? (
                            <Button
                                as="a"
                                href={`tel:${service.phoneNumber.replace(
                                    /[-\s]/g,
                                    '',
                                )}`}
                                className="btn-outlined">
                                {service.phoneNumber}
                            </Button>
                        ) : null}
                        {service.storePage && service.storePageLinkText ? (
                            <Button
                                as={Link}
                                locale={locale}
                                href={service.storePage.slug}
                                className="btn-outlined">
                                {service.storePageLinkText}
                            </Button>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
