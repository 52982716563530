import type { SearchProviderProps } from './Search.context';
import { SearchProvider } from './Search.context';

const SearchRoot = (props: SearchProviderProps) => {
    return <SearchProvider {...props} />;
};

SearchRoot.displayName = 'SearchRoot';

export default SearchRoot;
