import SearchForm from './SearchForm';
import SearchInput from './SearchInput';
import SearchRoot from './SearchRoot';
import SearchSubmit from './SearchSubmit';

const composedSearch = {
    Root: SearchRoot,
    Form: SearchForm,
    Input: SearchInput,
    Submit: SearchSubmit,
};

export default composedSearch;
