'use client';

import { useHeaderContext } from '@context/headerContext';
import useOutsideClick from '@hooks/useOutsideClick';
import { Button } from '@ngg/components';
import { ArrowDownIcon, ArrowUpIcon } from '@ngg/icons';
import type { HeaderStoreFragment } from '@server/gql/graphql';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { cn } from '@/lib/utils';

import StoreCard from './StoreCard';

export default function StorePopup({
    stores,
}: {
    stores: HeaderStoreFragment[];
}) {
    const { dictionary } = useHeaderContext();
    const [open, setOpen] = useState(false);
    const ref = useOutsideClick(() => setOpen(false));
    const pathname = usePathname();

    useEffect(() => setOpen(false), [pathname]);

    if (!stores.length) return null;

    return (
        <div className="relative h-full" ref={ref}>
            <Button
                onClick={() => setOpen(!open)}
                className={cn(
                    'relative h-full gap-1 px-2',
                    open ? 'bg-white text-black' : 'bg-inherit',
                )}
                endIcon={
                    open ? (
                        <ArrowUpIcon className="h-3.5 w-3.5" />
                    ) : (
                        <ArrowDownIcon className="h-3.5 w-3.5" />
                    )
                }>
                {dictionary?.stores}
            </Button>

            <div
                className={cn(
                    'min-w-92 absolute right-0 top-full z-50 divide-x border-x border-b bg-white',
                    open ? 'flex' : 'hidden',
                )}>
                {stores.map((store) => (
                    <StoreCard key={store?.sys.id} store={store} />
                ))}
            </div>
        </div>
    );
}
