import type { RefObject } from 'react';
import { useEffect } from 'react';

import type { UseSearchContextProps } from './Context';

export default function useOnClickOutside(
    ref: RefObject<HTMLDivElement>,
    handler: (event: MouseEvent | TouchEvent) => void,
    state: UseSearchContextProps,
) {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (!ref.current?.contains(event.target as HTMLElement))
                handler(event);
            else if (event.target !== (state.inputRef as any)?.current)
                event.preventDefault();
        };

        if (state.open) {
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
        }

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler, state]);
}
