import type { NavigationLink } from '@components/header/types';
import Link from '@components/Link';
import { useMarket } from '@context/marketContext';
import { MobileMenuContext } from '@context/mobileMenuContext';
import { ArrowRightIcon } from '@ngg/icons';
import { usePathname } from 'next/navigation';
import { useContext } from 'react';

import { cn } from '@/lib/utils';
import { useMenuStore } from '@/stores/menuStore';

type MobileMenuLinkProps = {
    link?: NavigationLink;
};

export default function MobileMenuLink({ link }: MobileMenuLinkProps) {
    const pathname = usePathname();
    const { locale } = useMarket().state;
    const { setNavigationTree, setNavigationDirection } =
        useContext(MobileMenuContext);
    const setMobileMenuOpen = useMenuStore((state) => state.setMobileMenuOpen);

    if (!link) return null;

    const isCurrentPage = pathname.endsWith(link?.slug || '');

    const buttonAndLinkClassName = cn(
        'flex w-full items-center justify-between px-4 py-3 text-left',
        isCurrentPage && 'underline',
    );

    if (link.children?.length)
        return (
            <div>
                <Link
                    href={link.slug}
                    locale={locale}
                    className={buttonAndLinkClassName}
                    onClick={(event) => {
                        event.preventDefault();

                        setNavigationTree((prev) => [...prev, link]);
                        setNavigationDirection('right');
                    }}>
                    {link.title}
                    <ArrowRightIcon className="h-4 w-4" />
                </Link>
            </div>
        );

    return (
        <div>
            <Link
                href={link.slug}
                locale={locale}
                className={buttonAndLinkClassName}
                onClick={() => setMobileMenuOpen(false)}>
                {link.title}
            </Link>
        </div>
    );
}
