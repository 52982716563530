'use client';

import { Input, Select } from '@components/inputs';
import { useForm, ValidationError } from '@formspree/react';
import { Button, Checkbox } from '@ngg/components';
import type { Form } from '@server/gql/graphql';
import { useMemo, useState } from 'react';
import type { Dictionary } from 'types/common';

export default function Return({
    dictionary,
    reference,
}: Form & {
    dictionary: Dictionary;
}) {
    const [state, handleSubmit] = useForm(reference || '');
    const [items, setItems] = useState<number[]>(() => [new Date().getTime()]);

    const reasons = useMemo(
        () => [
            {
                id: 1,
                value: dictionary?.returnFormValueExpectation,
                label: dictionary?.returnFormLabelExpectation,
            },
            {
                id: 2,
                value: dictionary?.returnFormValueNotSuitable,
                label: dictionary?.returnFormLabelNotSuitable,
            },
            {
                id: 3,
                value: dictionary?.returnFormValueMultiple,
                label: dictionary?.returnFormLabelMultiple,
            },
            {
                id: 4,
                value: dictionary?.returnFormValueOther,
                label: dictionary?.returnFormLabelOther,
            },
        ],
        [dictionary],
    );

    if (state.succeeded) {
        return (
            <div>
                <h2 className="text-lg font-bold">
                    {dictionary?.formSuccessHeading}
                </h2>
                <p className="mt-4 text-sm">{dictionary?.formSuccessText}</p>
            </div>
        );
    }
    const isInputError = (inputName: string) =>
        state?.errors.some((error) => error.field === inputName);

    return (
        <form
            className="not-prose mt-4 max-w-4xl text-sm md:mt-6"
            onSubmit={handleSubmit}>
            <fieldset className="grid grid-cols-1 gap-3 md:grid-cols-2">
                <div>
                    <Input
                        type="email"
                        label={dictionary?.formEmailLabel}
                        required
                        id="email"
                        name="email"
                        fullWidth
                        data-1p-ignore
                        classNames={{
                            input: isInputError('email') ? 'border-error' : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="email"
                        errors={state.errors}
                    />
                </div>
                <div>
                    <Input
                        type="number"
                        label={dictionary?.formOrderNumberLabel}
                        required
                        id="OrderNo"
                        name="OrderNo"
                        fullWidth
                        hint={dictionary?.formOrderNumberHint}
                        inputMode="numeric"
                        data-1p-ignore
                        classNames={{
                            input: isInputError('OrderNo')
                                ? 'border-error'
                                : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="OrderNo"
                        errors={state.errors}
                    />
                </div>
            </fieldset>
            <p className="mt-6 text-lg font-bold">
                {dictionary?.returnFormInfoText}
            </p>
            <div className="mt-6">
                {items.map((item, index) => (
                    <fieldset
                        key={item}
                        className=" border-t border-grey-150 bg-grey-100 p-3 pb-6 first:border-t-0">
                        <div className="flex w-full items-center justify-between text-xs uppercase">
                            <span>{`${dictionary?.product} #${
                                index + 1
                            }`}</span>
                            {index > 0 ? (
                                <button
                                    type="button"
                                    className="uppercase tracking-tighter text-grey-300 underline"
                                    onClick={() =>
                                        setItems((old) =>
                                            old.filter((i) => i !== item),
                                        )
                                    }>
                                    {dictionary?.remove}
                                </button>
                            ) : null}
                        </div>
                        <div className="mt-3 grid grid-cols-1 gap-3 md:grid-cols-2">
                            <div>
                                <Select
                                    name={`Reason-${index + 1}`}
                                    placeholder={
                                        dictionary?.returnFormReasonForReturnPlaceholder
                                    }
                                    options={reasons}>
                                    <ValidationError
                                        className="mt-2 text-sm"
                                        field={`Reason-${index + 1}`}
                                        errors={state.errors}
                                    />
                                </Select>
                            </div>
                            <div>
                                <Input
                                    type="number"
                                    label={
                                        dictionary?.returnFormArticleNumberInputLabel
                                    }
                                    required
                                    id={`ItemNo-${index + 1}`}
                                    name={`ItemNo-${index + 1}`}
                                    fullWidth
                                    hint={
                                        dictionary?.returnFormArticleNumberInputHint
                                    }
                                    inputMode="numeric"
                                    data-1p-ignore
                                    classNames={{
                                        input: isInputError(
                                            `ItemNo-${index + 1}`,
                                        )
                                            ? 'border-error'
                                            : '',
                                    }}
                                />
                                <ValidationError
                                    className="mt-2 text-sm"
                                    field={`ItemNo-${index + 1}`}
                                    errors={state.errors}
                                />
                            </div>
                        </div>
                    </fieldset>
                ))}
            </div>

            <fieldset className="mt-3 space-y-6">
                <div className="">
                    <button
                        type="button"
                        className="inline-block text-sm font-bold underline"
                        onClick={() =>
                            setItems((i) => [...i, new Date().getTime()])
                        }>
                        {dictionary?.returnFormAddItemButtonText}
                    </button>
                </div>
                <Checkbox
                    classNames={{ label: 'text-sm' }}
                    name="confirmation"
                    {...{ required: true }}>
                    {dictionary?.returnFormConfirmationCheckboxText}
                </Checkbox>
                <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                    <Button
                        type="submit"
                        fullWidth
                        isLoading={state.submitting}
                        className="btn-primary">
                        {dictionary?.returnFormCreateReturnButtonText}
                    </Button>
                </div>
            </fieldset>
        </form>
    );
}
