import { MobileMenuContext } from '@context/mobileMenuContext';
import { motion } from 'framer-motion';
import type { ReactNode } from 'react';
import { forwardRef, useContext } from 'react';

import { TRANSITION_CUBIC_BEZIER } from '@/config/transitions';

const leftAnimation = {
    initial: { translateX: '-100%' },
    animate: { translateX: '0%' },
    exit: { translateX: '100%' },
    transition: {
        ...TRANSITION_CUBIC_BEZIER,
        duration: 0.4,
    },
};

const rightAnimation = {
    initial: { translateX: '100%' },
    animate: { translateX: '0%' },
    exit: { translateX: '-100%' },
    transition: {
        ...TRANSITION_CUBIC_BEZIER,
        duration: 0.4,
    },
};

type MobileMenuDirectionAnimationProps = {
    children: ReactNode;
    className?: string;
    layout?: boolean;
};

const MobileMenuDirectionAnimation = forwardRef<
    HTMLDivElement,
    MobileMenuDirectionAnimationProps
>(({ children, className, ...props }, ref) => {
    const { navigationDirection } = useContext(MobileMenuContext);

    const transition =
        navigationDirection === 'right' ? rightAnimation : leftAnimation;

    return (
        <motion.div ref={ref} className={className} {...transition} {...props}>
            {children}
        </motion.div>
    );
});

MobileMenuDirectionAnimation.displayName = 'MobileMenuDirectionAnimation';

export default MobileMenuDirectionAnimation;
