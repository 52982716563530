import type { NavigationLink } from '../types';
import BrandDropdown from './BrandDropdown';
import CategoryDropdown from './CategoryDropdown';
import SimpleDropdown from './SimpleDropdown';

export default function Dropdown({
    activeLink,
    locale,
}: {
    activeLink: NavigationLink;
    locale?: string;
}) {
    if (!activeLink) return null;

    if (activeLink.type === 'Simple') {
        return <SimpleDropdown link={activeLink} locale={locale} />;
    }

    return (
        <div className="absolute left-0 top-0 w-full border-y border-grey-150 bg-white lg:top-full">
            <div className="container grid grid-cols-12">
                {activeLink.type === 'Brand' && (
                    <BrandDropdown link={activeLink} locale={locale} />
                )}
                {activeLink.type === 'Category' && (
                    <CategoryDropdown link={activeLink} locale={locale} />
                )}
            </div>
        </div>
    );
}
