'use client';

import { Button, Heading, Paragraph } from '@components/ui';
import { ArrowIcon } from '@ngg/icons';
import { useRouter } from 'next/navigation';

import { getNewCustomerUrl } from '@/utils/const';

type Props = {
    dictionary: {
        subscribeForDiscount: string;
        footerBannerNewCustomer: string;
        footerBannerSubscribeButton: string;
    };
    locale?: string;
};

export default function NewCustomerBanner(props: Props) {
    const { dictionary, locale } = props;

    const router = useRouter();

    return (
        <section className="bg-primary px-4 py-8 lg:flex lg:justify-between lg:py-16">
            <div className="container grid items-stretch gap-x-6 gap-y-6 p-3 md:grid-cols-12 lg:gap-x-12 lg:p-6">
                <div className="flex max-w-[40rem] flex-col gap-4 md:col-span-6">
                    <Heading
                        size={{
                            initial: 5,
                            lg: 4,
                        }}>
                        {dictionary.subscribeForDiscount}
                    </Heading>
                    <Paragraph
                        size={{
                            initial: 'small',
                            lg: 'medium',
                        }}>
                        {dictionary.footerBannerNewCustomer}
                    </Paragraph>
                </div>
                <div className="flex items-center justify-center md:col-span-6">
                    <Button
                        onClick={() => router.push(getNewCustomerUrl(locale))}
                        className="w-full grow gap-x-1 bg-black font-medium text-white lg:max-w-[24rem] lg:px-8 lg:py-6"
                        size={{
                            initial: 'xl',
                            lg: '2xl',
                        }}>
                        {dictionary.footerBannerSubscribeButton}
                        <ArrowIcon className="h-6 w-6" />
                    </Button>
                </div>
            </div>
        </section>
    );
}
