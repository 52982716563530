import ContentfulImage from '@components/ContentfulImage/ContentfulImage';
import type { NavigationSplash } from '@components/header/types';
import Link from '@components/Link/Link';
import { Paragraph } from '@components/ui/Paragraph/Paragraph';
import type { ReactNode } from 'react';

import { useMenuStore } from '@/stores/menuStore';

type HightlightLinkProps = {
    className?: string;
    children: ReactNode;
    slug?: string | null;
    locale?: string;
};

const HightlightLink = ({ slug, ...props }: HightlightLinkProps) => {
    const setMobileMenuOpen = useMenuStore((state) => state.setMobileMenuOpen);

    if (slug) {
        return (
            <Link
                href={slug}
                onClick={() => setMobileMenuOpen(false)}
                {...props}
            />
        );
    }

    return <div {...props} />;
};

type HighlightProps = {
    item?: NavigationSplash;
    locale?: string;
};

const Highlight = ({ item, locale }: HighlightProps) => {
    if (!item) return null;

    const { image, title, slug, content } = item;

    return (
        <HightlightLink slug={slug} locale={locale} className="px-3">
            {title && (
                <Paragraph className="mb-2 text-sm font-medium capitalize">
                    {title}
                </Paragraph>
            )}
            <ContentfulImage
                mobileImage={image as any}
                desktopImage={image as any}
                sizes="20em"
                className="aspect-square object-cover mix-blend-multiply"
            />
            {content && (
                <Paragraph className="mt-1.5 text-sm font-normal">
                    {content}
                </Paragraph>
            )}
        </HightlightLink>
    );
};

Highlight.displayName = 'Highlight';

export default Highlight;
