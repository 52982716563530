import { MobileMenuContext } from '@context/mobileMenuContext';
import { AnimatePresence } from 'framer-motion';
import { useContext, useMemo } from 'react';

import MobileMenuDirectionAnimation from './MobileMenuDirectionAnimation';
import MobileMenuHighlights from './MobileMenuHighlights';
import MobileMenuInfo from './MobileMenuInfo';

type MobileMenuBottomProps = {
    className?: string;
};

const MobileMenuBottom = ({ className }: MobileMenuBottomProps) => {
    const { navigationTree } = useContext(MobileMenuContext);

    const isFirstLevel = useMemo(
        () => navigationTree.length <= 1,
        [navigationTree],
    );

    const currentNavigationLink = useMemo(
        () => navigationTree?.at(-1),
        [navigationTree],
    );

    return (
        <div className={className}>
            <AnimatePresence initial={false} mode="popLayout">
                {!isFirstLevel && (
                    <MobileMenuDirectionAnimation key="mobile-menu-highlights">
                        <MobileMenuHighlights
                            className="flex flex-col gap-y-4 py-4 empty:hidden"
                            link={currentNavigationLink}
                        />
                    </MobileMenuDirectionAnimation>
                )}
                {isFirstLevel && (
                    <MobileMenuDirectionAnimation key="mobile-menu-info">
                        <MobileMenuInfo />
                    </MobileMenuDirectionAnimation>
                )}
            </AnimatePresence>
        </div>
    );
};

MobileMenuBottom.displayName = 'MobileMenuBottom';

export default MobileMenuBottom;
