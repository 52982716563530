import type { HeaderStoreFragment } from '@server/gql/graphql';

export type OpeningTime = {
    days: string[];
    closing?: string;
    opening?: string;
    open: boolean;
};

export default function getOpeningHours(
    store: HeaderStoreFragment,
): [OpeningTime[], OpeningTime[]] {
    if (!store.openingHoursCollection?.items?.length) return [[], []];
    const { items = [] } = store.openingHoursCollection;
    const output: OpeningTime[] = [];
    for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        if (item) {
            const {
                dayOfTheWeek = '',
                closingTime = '',
                openingTime = '',
                closed,
            } = item;
            const ref = output.at(-1);
            if (ref) {
                if (ref.closing === closingTime && ref.opening && openingTime) {
                    output.at(-1)?.days.push(dayOfTheWeek as string);
                } else if (!closed) {
                    output.push({
                        days: [dayOfTheWeek as string],
                        closing: closingTime as string,
                        opening: openingTime as string,
                        open: true,
                    });
                } else if (!ref.open) {
                    output.at(-1)?.days.push(dayOfTheWeek as string);
                } else {
                    output.push({
                        days: [dayOfTheWeek as string],
                        open: false,
                    });
                }
            } else if (!closed) {
                output.push({
                    days: [dayOfTheWeek as string],
                    closing: closingTime as string,
                    opening: openingTime as string,
                    open: true,
                });
            } else {
                output.push({
                    days: [dayOfTheWeek as string],
                    open: false,
                });
            }
        }
    }
    if (!store.exceptionalOpeningHoursCollection?.items.length) {
        return [output, []];
    }
    const { items: irregularItems = [] } =
        store.exceptionalOpeningHoursCollection;

    const irregular: OpeningTime[] = [];

    for (let i = 0; i < irregularItems.length; i += 1) {
        const item = irregularItems[i];
        if (item) {
            const { title, closed, openingTime, closingTime } = item;
            irregular.push({
                days: [title as string],
                ...(closed
                    ? {
                          open: false,
                      }
                    : {
                          open: true,
                          opening: openingTime as string,
                          closing: closingTime as string,
                      }),
            });
        }
    }
    return [output, irregular];
}
