import Link from '@components/Link';
import { ArrowIcon } from '@ngg/icons';
import type { HeaderNavigationLinkFragment } from '@server/gql/graphql';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { HTMLAttributes } from 'react';

import { cn } from '@/lib/utils';

type UspItemProps = HTMLAttributes<HTMLElement> & {
    item?: HeaderNavigationLinkFragment;
    locale?: string;
    hideArrow?: boolean;
};

type UspItemLinkProps = UspItemProps & VariantProps<typeof uspItemVariants>;

const uspItemVariants = cva(
    'flex-shrink-0 px-4 lg:px-3 leading-none underline-offset-2',
    {
        variants: {
            intent: {
                link: 'hover:underline',
            },
        },
    },
);

const USPItemLink = ({
    intent,
    className,
    item,
    locale,
    hideArrow,
    ...props
}: UspItemLinkProps) => {
    if (!item?.title) return null;

    const { internalLink } = item;

    if (internalLink && intent === 'link') {
        return (
            <Link
                className={cn(
                    uspItemVariants({ intent }),
                    'flex items-center gap-x-0.5 whitespace-nowrap',
                    className,
                )}
                href={internalLink.slug}
                locale={locale}
                {...props}>
                {item.title}
                {!hideArrow && <ArrowIcon className="h-4 w-4" />}
            </Link>
        );
    }

    return (
        <span className={cn(uspItemVariants({ intent }), className)} {...props}>
            {item.title}
        </span>
    );
};

const USPItem = ({ item, locale, ...props }: UspItemProps) => {
    if (!item) return null;

    const hasSlug = Boolean(item.internalLink?.slug);

    return (
        <USPItemLink
            item={item}
            locale={locale}
            intent={hasSlug ? 'link' : undefined}
            {...props}
        />
    );
};

export default USPItem;
