import Search from '@components/MobileSearch';
import { HeaderContext } from '@context/headerContext';
import { MobileMenuContext } from '@context/mobileMenuContext';
import { useSiteInfo } from '@context/siteInfoContext';
import { AnimatePresence } from 'framer-motion';
import { useContext, useMemo } from 'react';

import MobileMenuDirectionAnimation from './MobileMenuDirectionAnimation';
import MobileMenuTitle from './MobileMenuTitle';

const CONTENTFUL_MAGAZINE_ID = '6SOQkXtV8yLtm1Jr11k0Tr';

type MobileMenuTopProps = {
    className?: string;
};

export default function MobileMenuTop({
    className,
    ...props
}: MobileMenuTopProps) {
    const { globalDictionary: dictionary } = useSiteInfo();
    const { locale } = useContext(HeaderContext);
    const { navigationTree } = useContext(MobileMenuContext);

    const isFirstLevel = useMemo(
        () => navigationTree.length <= 1,
        [navigationTree],
    );
    const currentNavigationLink = useMemo(
        () => navigationTree?.at(-1),
        [navigationTree],
    );

    return (
        <AnimatePresence initial={false} mode="popLayout">
            {isFirstLevel ? (
                <MobileMenuDirectionAnimation
                    key="mobile-menu-search"
                    className={className}
                    {...props}>
                    <Search.Root locale={locale} dictionary={dictionary}>
                        <Search.Form>
                            <Search.Input className="border-t-0 p-4" />
                        </Search.Form>
                    </Search.Root>
                </MobileMenuDirectionAnimation>
            ) : (
                <MobileMenuDirectionAnimation
                    key="mobile-menu-title"
                    className={className}
                    {...props}>
                    {currentNavigationLink?.slug ? (
                        <>
                            {currentNavigationLink.type === 'Brand' && (
                                <div className="w-full" />
                            )}
                            {currentNavigationLink.type === 'Market' && (
                                <div className="flex w-full items-center justify-between border-b border-grey-150 px-4 py-4.5">
                                    <span className="text-sm font-medium capitalize">
                                        {dictionary.selectMarket}
                                    </span>
                                </div>
                            )}
                            {currentNavigationLink.type === 'Category' && (
                                <MobileMenuTitle
                                    showAllTitle={dictionary.showAll}
                                    link={currentNavigationLink}
                                />
                            )}
                            {currentNavigationLink.id ===
                                CONTENTFUL_MAGAZINE_ID && (
                                <MobileMenuTitle
                                    showAllTitle={dictionary.explore}
                                    link={currentNavigationLink}
                                />
                            )}
                        </>
                    ) : (
                        <div className="w-full" />
                    )}
                </MobileMenuDirectionAnimation>
            )}
        </AnimatePresence>
    );
}
