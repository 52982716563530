'use client';

import { Input, Select, Textarea } from '@components/inputs';
import { useForm, ValidationError } from '@formspree/react';
import { Button } from '@ngg/components';
import type { Form } from '@server/gql/graphql';
import { useMemo, useState } from 'react';
import type { Dictionary } from 'types';

export default function Claims({
    dictionary,
    reference,
}: Form & {
    dictionary: Dictionary;
}) {
    const [state, handleSubmit] = useForm(reference || '');
    const [uploadedFiles, setUploadedFiles] = useState<FileList | null>(null);

    const reasons = useMemo(
        () => [
            {
                id: 1,
                value: dictionary?.claimsFormValueBroken,
                label: dictionary?.claimsFormLabelBroken,
            },
            {
                id: 2,
                value: dictionary?.claimsFormValueNotWorking,
                label: dictionary?.claimsFormLabelNotWorking,
            },
            {
                id: 3,
                value: dictionary?.claimsFormValueNotAsDescribed,
                label: dictionary?.claimsFormNotAsDescribed,
            },
        ],
        [dictionary],
    );

    if (state.succeeded) {
        return (
            <>
                <h2 className="text-lg font-bold">
                    {dictionary?.formSuccessHeading}
                </h2>
                <p className="mt-4 text-sm">{dictionary?.formSuccessText}</p>
            </>
        );
    }

    const fileNames =
        uploadedFiles &&
        [...uploadedFiles]?.map((file) => file.name).join(', ');
    const isInputError = (inputName: string) =>
        state?.errors.some((error) => error.field === inputName);

    return (
        <form
            className="not-prose max-w-4xl text-sm space-y-3"
            onSubmit={handleSubmit}
            encType="multipart/form-data">
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                <div>
                    <Input
                        name="Email"
                        id="email"
                        type="email"
                        required
                        fullWidth
                        label={dictionary?.formEmailLabel}
                        classNames={{
                            input: isInputError('Email') ? 'border-error' : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="Email"
                        errors={state.errors}
                    />
                </div>
                <div>
                    <Select
                        name="Reason"
                        placeholder={dictionary?.claimsFormReturnReasonLabel}
                        required
                        options={reasons}>
                        <ValidationError
                            className="mt-2 text-sm"
                            field="Reason"
                            errors={state.errors}
                        />
                    </Select>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                <div>
                    <Input
                        type="number"
                        label={dictionary?.formOrderNumberLabel}
                        required
                        fullWidth
                        name="Order Number"
                        hintId="orderNumberHint"
                        hint={dictionary?.formOrderNumberHint}
                        classNames={{
                            input: isInputError('Order Number')
                                ? 'border-error'
                                : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="Order Number"
                        errors={state.errors}
                    />
                </div>
                <div>
                    <Input
                        type="number"
                        label={dictionary?.claimsFormArticleNumberLabel}
                        required
                        fullWidth
                        name="Item Number"
                        hintId="articleNumberHint"
                        hint={dictionary?.claimsFormArticleNumberHint}
                        classNames={{
                            input: isInputError('Item Number')
                                ? 'border-error'
                                : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="Item Number"
                        errors={state.errors}
                    />
                </div>
            </div>

            <Textarea
                label={dictionary?.claimsFormTextareaLabel}
                required
                name="Description"
                fullWidth
                classNames={{
                    textarea: isInputError('Description') ? 'border-error' : '',
                }}
            />
            <ValidationError
                className="mt-2 text-sm"
                field="Description"
                errors={state.errors}
            />

            <div className="relative">
                <Input
                    type="text"
                    readOnly
                    label={fileNames || dictionary?.claimsFormUploadInputLabel}
                    fullWidth
                    classNames={{
                        label: uploadedFiles?.length ? 'text-black' : '',
                        input: isInputError('Photos') ? 'border-error' : '',
                    }}
                    endAdornment={
                        <div className="pointer-events-none absolute bottom-2 right-2 top-2 flex items-center bg-black p-2 text-xs font-medium text-white">
                            {dictionary?.formUploadEndAdornmentText}
                        </div>
                    }
                />

                <input
                    name="Photos"
                    id="file"
                    type="file"
                    onChange={(e) => setUploadedFiles(e.target.files)}
                    className="absolute inset-0 opacity-0"
                    accept="image/png, image/gif, image/jpeg"
                    multiple
                />
                <ValidationError
                    className="mt-2 text-sm"
                    field="Photos"
                    errors={state.errors}
                />
            </div>

            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                <Button
                    type="submit"
                    fullWidth
                    isLoading={state.submitting}
                    className="btn-primary mt-6">
                    {dictionary?.claimsFormActionButtonText}
                </Button>
            </div>
        </form>
    );
}
