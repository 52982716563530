import ContentfulImage from '@components/ContentfulImage/ContentfulImage';
import Link from '@components/Link/Link';
import { Paragraph } from '@components/ui/Paragraph/Paragraph';
import { motion } from 'framer-motion';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import { useMenuStore } from '@/stores/menuStore';

import type { NavigationSplash } from '../types';

const Wrapper = ({
    slug,
    children,
    locale,
    className,
}: {
    slug?: string | null;
    children: ReactNode;
    locale?: string;
    className?: string;
}) => {
    const { clearActiveWithDelay } = useMenuStore();

    return slug ? (
        <Link
            locale={locale}
            onClick={() => clearActiveWithDelay()}
            href={slug}
            className={className}>
            {children}
        </Link>
    ) : (
        <div className={className}>{children}</div>
    );
};

type HighLightProps = {
    item?: NavigationSplash;
    locale?: string;
};

const DropdownHighlight = forwardRef<HTMLDivElement, HighLightProps>(
    ({ item, locale }, ref) => {
        if (!item) {
            return null;
        }

        const { image, slug, title, content } = item;

        if (!image) {
            return null;
        }

        return (
            <motion.div
                ref={ref}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <Wrapper
                    slug={slug}
                    locale={locale}
                    className="block max-w-full space-y-2">
                    <ContentfulImage
                        className="aspect-square object-cover mix-blend-multiply"
                        mobileImage={image as any}
                        desktopImage={image as any}
                        sizes="18em"
                    />
                    {title && (
                        <Paragraph className="mb-2 text-sm font-medium capitalize">
                            {title}
                        </Paragraph>
                    )}
                    {content && (
                        <Paragraph className="mt-1.5 text-sm font-normal">
                            {content}
                        </Paragraph>
                    )}
                </Wrapper>
            </motion.div>
        );
    },
);

export default DropdownHighlight;
