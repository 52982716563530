import { type ComponentPropsWithoutRef } from 'react';

import { search } from '@/actions/search';

import { useSearchContext } from './Search.hooks';

type SearchFormProps = ComponentPropsWithoutRef<'form'>;

const SearchForm = ({ children, className, ...props }: SearchFormProps) => {
    const { locale, formRef } = useSearchContext();

    return (
        <form ref={formRef} className={className} action={search} {...props}>
            {children}
            <input type="hidden" name="locale" value={locale} />
        </form>
    );
};

SearchForm.displayName = 'SearchForm';

export default SearchForm;
